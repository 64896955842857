<template>
    <div>
        <div class="page-title">用量查询</div>

        <a-tabs v-model="key" @change="getFirstList">
            <template>
                <a-tab-pane :key="item.key" v-for="(item) in items">
                    <div slot="tab">
                        <span>{{ item.name }}</span>
                        <small> 剩余{{ item.balance }}次</small>
                    </div>
                </a-tab-pane>
            </template>
        </a-tabs>

        <a-table :columns="table.columns"
                 :rowKey="record => record.id || record.key"
                 :data-source="table.data"
                 :pagination="table.pagination"
                 @change="tableChanged"
                 :locale="{emptyText:'暂无记录'}"
                 class="members-table"
                 size="small"
                 :loading="table.loading"
        >
            <div slot="no" slot-scope="id,item,idx">
                {{ idx + 1 }}
            </div>

            <div slot="time" slot-scope="time">
                {{ $formatTime(time) }}
            </div>

            <div slot="member-name" slot-scope="member">
                <template v-if="member">
                    <router-link :to="`/ast/members/${member.id}`">{{ member.mark_name || member.name }}</router-link>
                </template>
            </div>

            <div slot="member-id" slot-scope="mid,item">
                <template v-if="mid">
                    <router-link :to="`/ast/members/${item.ast_member.id}`">{{ mid }}</router-link>
                </template>
            </div>

            <div slot="member-avatar" slot-scope="avatar">
                <template v-if="avatar">
                    <img class="avatar" :src="avatar" alt="">
                </template>
            </div>
        </a-table>

    </div>
</template>

<script>
import api from "@/repo/api";

export default {
    name: "Item",
    data() {
        return {
            query: {},
            key: '',
            items: [
                {
                    name: 'AI检测',
                    key: 'detect_deduct_count',
                    balance: 0,
                },
                {
                    name: '年度衣橱兑换',
                    key: 'wd_year_num',
                    balance: 0,
                },
                {
                    name: '印刷抵扣',
                    key: 'print_ts',
                    balance: 0,
                }
            ],
            table: {
                columns: [
                    {
                        title: 'ID',
                        dataIndex: 'id',
                        width: '110px',
                    },
                    {
                        title: '时间',
                        dataIndex: 'created_at',
                        scopedSlots: {customRender: 'time'},
                        width: '150px',
                    },
                    {
                        title: '用户ID',
                        dataIndex: 'ast_member.member_id',
                        scopedSlots: {customRender: 'member-id'},
                    },
                    {
                        title: '用户名称',
                        dataIndex: 'ast_member',
                        scopedSlots: {customRender: 'member-name'},
                    },
                    {
                        title: '用户头像',
                        dataIndex: 'ast_member.avatar',
                        scopedSlots: {customRender: 'member-avatar'},
                    },
                    {
                        title: '消耗',
                        dataIndex: 'num',
                    }, {
                        title: '备注',
                        dataIndex: 'title'
                    }
                ],
                loading: true,
                pagination: {
                    defaultCurrent: 1,
                    current: 1,
                    defaultPageSize: 10,
                    total: 1,
                    pageSize: 15,
                    showTotal: (total, range) => {
                        return `当前${range[0]}-${range[1]}，共 ${total} 个会员`;
                    },
                },
                data: []
            },
        }
    },
    mounted() {
        this.fetchConfig()
    },
    methods: {
        fetchConfig() {

            this.key = this.items[0].key

            api.get('/ast-app/balance', (data) => {

                if (data.code === 0) {

                    this.items.forEach(item => {

                        item.balance = data.data[item.key] || 0

                    })

                    this.getFirstList()
                }

            })
        },
        getList() {

            this.table.loading = true

            this.query.type = this.key

            api.get('/ast-app/product-logs', this.query, (data) => {
                this.table.data = data.data.data
                this.table.pagination.current = data.data.current_page
                this.table.pagination.total = data.data.total
                this.table.pagination.pageSize = data.data.per_page

                this.table.loading = false

            })
        },
        getFirstList() {
            this.query.page = 1
            this.getList()
        },
        tableChanged(pagination) {
            this.query.page = pagination.current
            this.getList()
        }
    }
}
</script>

<style scoped lang="less">
.avatar {
    width: 40px;
    border-radius: 50%;
}
</style>